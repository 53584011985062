import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import maastaAxios from "./plugins/axios";
import VueMeta from "vue-meta";
import { PiniaVuePlugin } from "pinia";
import pinia from "@/plugins/pinia";
import VueSecureHTML from "vue-html-secure";
import VScrollLock from "v-scroll-lock";
import MaastaLayout from "@/layouts/MaastaLayout.vue";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "./scss/styles.scss";

Vue.use(PiniaVuePlugin);
Vue.config.productionTip = false;
Vue.component("MaastaLayout", MaastaLayout);
Vue.use(VueMeta);
Vue.use(VueSecureHTML);
Vue.use(VScrollLock);
Vue.prototype.$axios = maastaAxios;

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
