import constants from "@/helpers/constants";

export default [
  {
    path: "/admin/create-blog",
    name: "create-blog",
    component: () =>
      import(
        /* webpackChunkName: "create-blog" */ "../views/admin/CreateBlog.vue"
      ),
    meta: {
      layout: "AdminLayout",
      auth: true,
      accessLevel: constants.ACCESS_ADMIN_ONLY,
    },
  },
  {
    path: "/admin/create-contest",
    name: "create-contest",
    component: () =>
      import(
        /* webpackChunkName: "create-contest" */ "../views/admin/CreateContest.vue"
      ),
    meta: {
      layout: "AdminLayout",
      auth: true,
      accessLevel: constants.ACCESS_ORGANIZERS,
    },
  },
  {
    path: "/admin/manage-contest",
    name: "manage-contests",
    component: () =>
      import(
        /* webpackChunkName: "manage-contests" */ "../views/admin/ManageContest.vue"
      ),
    meta: {
      layout: "AdminLayout",
      auth: true,
      accessLevel: constants.ACCESS_ORGANIZERS,
    },
  },
  {
    path: "/admin/manage-blogs",
    name: "manage-blogs",
    component: () =>
      import(
        /* webpackChunkName: "manage-blogs" */ "../views/admin/ManageBlogs.vue"
      ),
    meta: {
      layout: "AdminLayout",
      auth: true,
      accessLevel: constants.ACCESS_ADMIN_ONLY,
    },
  },
  {
    path: "/admin/manage-users",
    name: "manage-users",
    component: () =>
      import(/* webpackChunkName: "manage-users" */ "../views/admin/ManageUsers.vue"),
    meta: {
      layout: "AdminLayout",
      auth: true,
      accessLevel: constants.ACCESS_ADMIN_ONLY,
    },
  },
  {
    path: "/admin/manage-organizers",
    name: "manage-organizers",
    component: () =>
      import(/* webpackChunkName: "manage-organizers" */ "../views/admin/ManageOrganizers.vue"),
    meta: {
      layout: "AdminLayout",
      auth: true,
      accessLevel: constants.ACCESS_ADMIN_ONLY,
    },
  },
  {
    path: "/admin/manage-categories",
    name: "manage-categories",
    component: () =>
      import(/* webpackChunkName: "manage-categories" */ "../views/admin/ManageCategories.vue"),
    meta: {
      layout: "AdminLayout",
      auth: true,
      accessLevel: constants.ACCESS_ADMIN_ONLY,
    },
  },
  {
    path: "/admin/manage-skills",
    name: "manage-skills",
    component: () =>
      import(/* webpackChunkName: "manage-skills" */ "../views/admin/ManageSkills.vue"),
    meta: {
      layout: "AdminLayout",
      auth: true,
      accessLevel: constants.ACCESS_ADMIN_ONLY,
    },
  },
  {
    path: "/admin/manage-stories",
    name: "manage-stories",
    component: () =>
      import(/* webpackChunkName: "manage-stories" */ "../views/admin/ManageTopStories.vue"),
    meta: {
      layout: "AdminLayout",
      auth: true,
      accessLevel: constants.ACCESS_ADMIN_ONLY,
    },
  },
];
