import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import UserRoutes from "./user_routes";
import AdminRoutes from "./admin_routes";
import pinia from "@/plugins/pinia";
import { useUserStore } from "@/store/user";
import { useAlertStore } from "@/store/alert";
import constants from "@/helpers/constants";

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  ...UserRoutes,
  ...AdminRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/ErrorPage.vue"),
    props: true,
  },
  {
    path: "/error",
    name: "error-page",
    component: () =>
      import(/* webpackChunkName: "error-page" */ "../views/ErrorPage.vue"),
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore(pinia);
  const alertStore = useAlertStore(pinia);
  if (userStore.isAuthorized === null) {
    userStore
      .preflight()
      .then((response) => {
        switch (response.action) {
          case "logout":
            alertStore.alertType = "error";
            alertStore.alertMessage = `Your session got expired, Login again to continue`;
            next({ name: "login" });
            break;
          case "onboard":
            next({ name: "home" });
            break;
          case "continue":
            routeValidator(to, next, userStore, alertStore);
            break;
          case undefined:
            if (to.meta?.auth === true) {
              if (response?.code === 498) {
                next({ name: "login" });
              } else {
                next({
                  name: "error-page",
                  params: {
                    title: "Oops!",
                    message: `Something went wrong while checking your session, ${
                      response.message || response
                    }`,
                  },
                });
              }
            } else {
              next();
            }
            break;
          default:
            next({
              name: "error-page",
              params: {
                title: "Oops!",
                message: `Something went wrong while checking your session, ${
                  response.message || response
                }!`,
              },
            });
            break;
        }
      })
      .catch((error) => {
        alertStore.alertType = "error";
        alertStore.alertMessage = `We are not able to process your request, ${error.message}`;
        next({
          name: "error-page",
          params: {
            title: "Aw Snap :(",
            message: `Something went wrong while checking your session, ${
              error.message || error
            }`,
          },
        });
      });
  } else {
    routeValidator(to, next, userStore, alertStore);
  }
});

function routeValidator(to, next, userStore, alertStore) {
  if (to.meta.auth) {
    // check which type of auth is required
    if (to.meta.accessLevel) {
      // requires a higher role to access
      // check if role is admin or organizer
      // if yes next else next to unauthorized page
      if (to.meta.accessLevel === constants.ACCESS_ADMIN_ONLY) {
        if (userStore.role === constants.ROLE_ADMIN) {
          next();
        } else {
          next({
            name: "error-page",
            params: {
              title: "401 - Unauthorized!",
              message:
                "You're not authorized to view this page or perform the operation",
            },
          });
        }
      } else if (to.meta.accessLevel === constants.ACCESS_ORGANIZERS) {
        if (
          userStore.role === constants.ROLE_ORGANIZER ||
          userStore.role === constants.ROLE_ADMIN
        ) {
          next();
        } else {
          next({
            name: "error-page",
            params: {
              title: "401 - Unauthorized!",
              message:
                "You're not authorized to view this page or perform the operation",
            },
          });
        }
      } else {
        if (userStore.isAuthorized === true) {
          next();
        } else {
          next({
            name: "login",
          });
          alertStore.alertType = "warning";
          alertStore.alertMessage = "You needed to be logged in to continue!";
        }
      }
    } else {
      // no access level required, so check if user logged in and go next
      if (userStore.isAuthorized === true) {
        next();
      } else {
        next({
          name: "login",
        });
        alertStore.alertType = "warning";
        alertStore.alertMessage = "You needed to be logged in to continue!";
      }
    }
  } else {
    // no auth required, so next
    // next
    next();
  }
}

export default router;
