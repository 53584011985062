export default [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "tandc",
    component: () =>
      import(
        /* webpackChunkName: "Terms and Conditions" */ "../views/TermsConditions.vue"
      ),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacypolicy",
    component: () =>
      import(
        /* webpackChunkName: "Privacy Policy" */ "../views/PrivacyPolicy.vue"
      ),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/refund-policy",
    name: "refundpolicy",
    component: () =>
      import(
        /* webpackChunkName: "Refund Policy" */ "../views/RefundPolicy.vue"
      ),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/organizer",
    name: "organizer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Organizer.vue"),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Blogs.vue"),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/blog/:slug",
    name: "blog-detail",
    component: () =>
      import(/* webpackChunkName: "blog-detail" */ "../views/BlogDetail.vue"),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
    props: true,
  },
  {
    path: "/contests",
    name: "contest",
    component: () =>
      import(/* webpackChunkName: "contest" */ "../views/Event.vue"),
    props: (route) => Object.assign({}, route.query, route.params),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/contests/category/:category",
    name: "categorizedEvents",
    component: () =>
      import(
        /* webpackChunkName: "categorizedEvents" */ "../views/CategorizedEvents.vue"
      ),
    props: true,
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/contests/details/:id",
    name: "eventdetail",
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/EventDetail.vue"),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },

  {
    path: "/auth/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    meta: {
      layout: "AuthLayout",
    },
  },
  {
    path: "/auth/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/RegisterView.vue"),
    meta: {
      layout: "AuthLayout",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
    meta: {
      layout: "UserLayout",
      auth: true,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
];
