<template>
  <component :is="layout">
    <v-alert
      style="z-index: 99999"
      transition="slide-x-reverse-transition"
      border="left"
      :type="alertStore.alertType || 'info'"
      max-width="500"
      class="global-alert"
      v-model="alertStore.isAlertVisible"
    >
      {{ alertStore.alertMessage }}
      <template v-slot:append>
        <v-btn icon @click="alertStore.alertMessage = null">
          <v-icon> {{ mdiCloseCircle }} </v-icon>
        </v-btn>
      </template>
    </v-alert>
    <OnboardDialog v-if="userStore.showOnboardDialog"></OnboardDialog>
    <slot></slot>
  </component>
</template>

<script>
import { mapStores } from "pinia";
import { useAlertStore } from "@/store/alert";
import { useUserStore } from "@/store/user";
import OnboardDialog from "@/components/OnboardDialog.vue";
import { mdiCloseCircle } from "@mdi/js";
export default {
  name: "MaastaLayout",
  computed: {
    ...mapStores(useAlertStore, useUserStore),
    layout() {
      const layout = this.$route.meta.layout || "DefaultLayout";
      return () => import(`@/layouts/${layout}.vue`);
    },
  },
  data: () => ({
    mdiCloseCircle,
  }),
  components: { OnboardDialog },
};
</script>
