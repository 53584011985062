<template>
  <div>
    <v-dialog
      v-model="userStore.showOnboardDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-dialog v-model="isAlertActive" persistent width="300">
        <v-card v-if="localMessage.type === 'success'">
          <v-card-title> Success! </v-card-title>
          <v-card-text>
            {{ localMessage.message }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn @click="isAlertActive = false" color="secondary">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else-if="localMessage.type === 'error'">
          <v-card-title> Something went wrong! </v-card-title>
          <v-card-text>
            {{ localMessage.message }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn @click="isAlertActive = false" color="secondary">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-title> Note </v-card-title>
          <v-card-text>
            {{ localMessage.message }}
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Welcome aboard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="userStore.showOnboardDialog = false">
              Skip
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form ref="detailsForm" lazy-validation v-model="valid">
            <v-list two-line subheader>
              <v-subheader>
                Welcome to Maasta, We need some of your details to allow you
                continue using maasta and services
              </v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Username *</v-list-item-title>
                  <v-list-item-subtitle>
                    No spaces or special characters or emojis allowed
                  </v-list-item-subtitle>
                  <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    required
                    outlined
                    prefix="@"
                    flat
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Phone number *</v-list-item-title>
                  <v-list-item-subtitle>
                    Your phone number
                  </v-list-item-subtitle>
                  <v-text-field
                    v-model="phone"
                    :rules="phoneRules"
                    prefix="+91"
                    required
                    outlined
                    label="7890003155"
                    solo
                    flat
                    type="number"
                    :maxlength="10"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Date of birth *</v-list-item-title>
                  <v-list-item-subtitle>
                    Select your date of birth
                  </v-list-item-subtitle>
                  <v-menu
                    ref="dob_menu"
                    v-model="dob_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_of_birth"
                        :rules="dobRules"
                        required
                        outlined
                        label="Date of birth"
                        readonly
                        solo
                        flat
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_of_birth"
                      :active-picker.sync="selected_date"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substring(0, 10)
                      "
                      min="1940-01-01"
                      @change="saveDate"
                    ></v-date-picker>
                  </v-menu>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Location *</v-list-item-title>
                  <v-list-item-subtitle> District, State </v-list-item-subtitle>
                  <v-text-field
                    v-model="location"
                    :rules="stateRules"
                    required
                    outlined
                    label="District, State"
                    solo
                    flat
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Gender</v-list-item-title>
                  <v-list-item-subtitle>
                    If you don't want to mention it leave it as it is
                  </v-list-item-subtitle>
                  <v-select
                    :items="listOfGenders"
                    item-text="text"
                    item-value="value"
                    outlined
                    label="Select Gender"
                    solo
                    flat
                    v-model="gender"
                  >
                  </v-select>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list two-line subheader>
              <v-list-item v-if="listOfAvailableTalents.length > 0">
                <v-list-item-content>
                  <v-list-item-title> Intrested Event Types </v-list-item-title>
                  <v-list-item-subtitle>
                    Select your intrested type of events
                  </v-list-item-subtitle>
                  <v-select
                    :items="listOfAvailableTalents"
                    item-text="title"
                    item-value="title"
                    outlined
                    label="Select Interested types of events"
                    solo
                    multiple
                    flat
                    required
                    chips
                    v-model="intrestedTalents"
                  >
                  </v-select>
                </v-list-item-content>
              </v-list-item>
              <v-skeleton-loader
                v-else
                class="mx-auto"
                type="card"
              ></v-skeleton-loader>
              <v-list-item
                v-if="userStore.role !== 'user' && userStore.role !== null"
              >
                <v-list-item-content>
                  <v-list-item-title> Website </v-list-item-title>
                  <v-list-item-subtitle>
                    Your official website, incase if you have else leave it
                  </v-list-item-subtitle>
                  <v-text-field
                    v-model="website"
                    outlined
                    label="example.com"
                    solo
                    flat
                    prefix="https://"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn
              class="mx-4"
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="startOnBoarding()"
            >
              Update
            </v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useUserStore } from "@/store/user";
import { useAlertStore } from "@/store/alert";
export default {
  name: "OnboardDialog",
  computed: {
    ...mapStores(useUserStore, useAlertStore),
    isAlertActive: {
      get() {
        return this.localMessage.message != null;
      },
      set(value) {
        if (!value) {
          this.localMessage.message = null;
          this.localMessage.type = null;
        }
      },
    },
  },
  metaInfo: {
    title: "Onboarding - Maasta, A platform for talents",
  },
  data: () => ({
    username: null,
    gender: "N/A",
    phone: null,
    date_of_birth: null,
    location: null,
    intrestedTalents: null,
    website: null,
    isSaving: false,
    // meta
    listOfGenders: [
      {
        value: "male",
        text: "Male",
      },
      {
        value: "female",
        text: "Female",
      },
      {
        value: "others",
        text: "Others",
      },
      {
        value: "deny",
        text: "Deny to say",
      },
      {
        value: "N/A",
        text: "Not Selected",
      },
    ],
    listOfAvailableTalents: [],
    valid: true,
    dob_menu: false,
    selected_date: null,
    // rules
    usernameRules: [
      (v) => !!v || "username is required",
      (v) => (v && v?.length > 5) || "Username should be at least 5 characters",
      (v) => /^[a-zA-Z0-9_]+$/.test(v) || "Invalid Username",
    ],
    dobRules: [(v) => !!v || "Date of birth is required"],
    stateRules: [(v) => !!v || "Location is required"],
    phoneRules: [
      (v) => !!v || "Phone number is required",
      (v) => (v && v.length > 1 && v.length <= 10) || "Invalid Phone number",
    ],
    intrestedTalentsRules: [],
    loading: false,
    localMessage: {
      message: null,
      type: "info",
    },
  }),
  mounted() {
    this.$axios
      .get("/onboardskills/")
      .then((response) => {
        this.listOfAvailableTalents = response.data.data;
      })
      .catch((error) => {
        this.localMessage.type = "error";
        this.localMessage.message = `${
          error.response ? error.response.data.message : error.message || error
        }`;
      });
  },
  methods: {
    startOnBoarding() {
      this.loading = true;
      this.userStore
        .oAuthUserOnBoard(
          this.username,
          this.gender,
          this.date_of_birth,
          this.location,
          this.phone,
          "both",
          this.intrestedTalents,
          "",
          this.website || "Not provided"
        )
        .then((response) => {
          switch (response.action) {
            case "continue":
              this.localMessage.type = "success";
              this.localMessage.message = response.message;
              setTimeout(() => {
                this.localMessage.type = "info";
                this.localMessage.message = null;
              }, 3000);
              if (this.$refs.detailsForm !== undefined) {
                this.$refs.detailsForm.reset();
              }
              this.userStore.preflight().finally(() => {
                this.userStore.showOnboardDialog = false;
              });
              break;
            case "reject":
              this.localMessage.type = "error";
              this.localMessage.message = response.message;
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          this.localMessage.type = "error";
          if (error.response) {
            this.localMessage.message = `${error.response.data.message}`;
          } else {
            this.localMessage.message =
              "Unable to complete the process, check your internet connectivity and try later";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveDate(date_of_birth) {
      this.$refs.dob_menu.save(date_of_birth);
    },
  },
  watch: {
    dob_menu(val) {
      val && setTimeout(() => (this.selected_date = "YEAR"));
    },
  },
};
</script>
