import { defineStore } from "pinia";
import $axios from "@/plugins/axios";

export const useUserStore = defineStore("user", {
    state: () => ({
        isAuthorized: null,
        role: null,
        user: null,
        showOnboardDialog: false,
        savedEvents: [],
        showWorkUploadDialog: false,
    }),
    getters: {
        canManage() {
            return this.isAuthorized === true && (this.role === 'admin' || this.role === 'organizer');
        },
        isAdmin() {
            return this.role === "admin";
        },
        isOrganizer() {
            return this.role === "organizer";
        },
        isUser() {
            return this.role === "user";
        },
        isLoggedIn() {
            return this.isAuthorized !== null && this.role !== null;
        },
        isUserOnboard() {
            return this.user?.phone;
        }
    },
    actions: {
        async loginUser(email, password) {
            try {
                const response = await $axios.post("/auth/login", {
                    email: email,
                    password: password,
                });
                this.user = response.data.user;
                this.role = response.data.user.role;
                this.isAuthorized = true;
                return {
                    status: 200,
                    action: response.data.action,
                    data: response.data,
                }
            } catch (error) {
                if (error.response) {
                    return {
                        status: error.response.status,
                        action: error.response.data.action,
                        message: error.response.data.message,
                    }
                } else {
                    // network error
                    return {
                        status: 499,
                        action: "reject",
                        message: "Unable to connect with the remote server, check your connection"
                    }
                }
            }
        },
        async oAuthUser(platform = "facebook", g_token, f_id, f_name, f_email, f_picture) {
            // platform, g_token, f_name, f_email, f_id, f_picture
            if (platform === "google") {
                try {
                    const response = await $axios.post("/auth/oauth2", {
                        platform: "google",
                        g_token: g_token,
                    });
                    this.isAuthorized = true;
                    this.user = response.data.user;
                    this.role = response.data.user.role;
                    return {
                        status: response.status,
                        action: response.data.action,
                        data: response.data,
                        message: "Successful authentication using google"
                    };
                } catch (error) {
                    if (error.response) {
                        return {
                            message: error.response.data.message,
                            code: error.response.status,
                            action: error.response.data.action,
                        }
                    } else {
                        return {
                            code: 499,
                            action: "reject",
                            message: "Unable to continue further, please check your internet connection before proceeding",
                        }
                    }
                }
            } else if (platform === "facebook") {
                try {
                    const response = await $axios.post("/auth/oauth2", {
                        platform: "facebook",
                        f_name: f_name,
                        f_email: f_email,
                        f_id: f_id,
                        f_picture: f_picture,
                    });
                    return {
                        message: response.data.message,
                        action: response.data.action,
                        data: response.data,
                    }
                } catch (error) {
                    this.$reset()
                    if (error.response) {
                        return {
                            message: error.response.data.message,
                            code: error.response.status,
                            action: error.response.data.action,
                        }
                    } else {
                        return {
                            message: "Unable to continue further, please check your internet connection before proceeding",
                            code: 499,
                            action: "reject"
                        };
                    }
                }
            } else {
                this.$reset()
                return {
                    message: "Invalid Platform selected, please try again later",
                    code: 499,
                    action: "reject"
                };
            }
        },
        async oAuthUserOnBoard(
            username,
            gender,
            date_of_birth,
            location,
            phone,
            intrestedMode,
            intrestedTalents,
            bio,
            website = "Not Specified"
        ) {
            try {
                const response = await $axios.post("/auth/oauth2/onboard", {
                    username: username,
                    gender: gender,
                    date_of_birth: date_of_birth,
                    location: location,
                    phone: phone,
                    intrestedMode: intrestedMode,
                    intrestedTalents: intrestedTalents,
                    bio: bio,
                    website: website,
                });
                return {
                    message: response.data.message,
                    data: response.data,
                    action: response.data.action
                };
            } catch (error) {
                if (error.response) {
                    return {
                        message: error.response.data.message,
                        action: error.response.data.action,
                        status: error.response.status,
                    }
                } else {
                    return {
                        message: "Unable to process the request, check your internet connection",
                        action: "reject",
                        status: 499,
                    }
                }
            }
        },
        async logoutUser() {
            try {
                const response = await $axios.delete("/auth/logout");
                this.$reset();
                return {
                    status: response.status,
                    action: "logout",
                    message: response.data.message,
                };
            } catch (error) {
                if (error.response) {
                    this.$reset()
                    return {
                        status: error.response.status,
                        message: error.response.data.message,
                        action: "logout",
                    };
                } else {
                    this.$reset()
                    return {
                        status: 499,
                        message: "Unable to process your request",
                    };
                }
            }
        },
        async preflight() {
            try {
                const response = await $axios.get("/auth/preflight");
                this.isAuthorized = true;
                this.role = response.data.role;
                this.user = response.data.user;
                return {
                    message: response.data.message,
                    code: response.status,
                    data: response.data,
                    action: response.data.action
                }
            } catch (error) {
                this.isAuthorized = false;
                if (error.response) {
                    return {
                        message: error.response.data.message,
                        code: error.response.status,
                        error: error.response.data.error,
                        action: error.response.data.action
                    }
                } else {
                    return {
                        message: "Unable to connect with maasta, check your connectivity and try later",
                        code: 499,
                        action: "reject",
                        error: error,
                    }
                }
            }
        },
        async loadSavedEvents() {
            if (this.isLoggedIn === false) {
                this.savedEvents = [];
            } else {
                try {
                    const response = await $axios.get("/auth/users");
                    // bad strategy need to fix this, create a new endpoint that just returns all the saved events by user
                    this.savedEvents = response.data?.user === undefined ? response.data?.users[response.data.users.findIndex(user_item => user_item.email === this.user.email)]?.saved_events : response.data?.user?.saved_events;
                } catch (error) {
                    if (error.response) {
                        console.warn("failed to get saved events", error.response?.data);
                        this.savedEvents = [];
                    } else {
                        console.warn("failed to get saved events", error);
                        this.savedEvents = [];
                    }
                }
            }
        },
        async forgotPassword(email, token) {
            try {
                const response = await $axios.post("/auth/forgot", {
                    email: email,
                    gRecaptchaResponse: token
                })
                return {
                    status: response.status,
                    action: "continue",
                    message: response.data?.message
                }
            } catch (error) {
                if (error.response) {
                    return {
                        status: error.response.status,
                        action: "failed",
                        message: error.response.data.message,
                    }
                } else {
                    return {
                        status: 499,
                        action: "reject",
                        message: "Unable to connect with the remote server, check your connection"
                    }
                }
            }
        },
    },
})