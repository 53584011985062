import { defineStore } from "pinia";

export const useAlertStore = defineStore("alert", {
    state: () => ({
        alertMessage: null,
        alertType: "info",
        dialogMessage: null,
        dialogType: "info",
    }),
    getters: {
        isAlertVisible: (state) => state.alertMessage !== null,
        isDialogVisible: (state) => state.dialogMessage !== null
    },
    actions: {
        closeAlert() {
            this.$reset();
        }
    }
});