export default {
    ROLE_USER: "user",
    ROLE_ADMIN: "admin",
    ROLE_ORGANIZER: "organizer",
    ACCESS_ALL: "user",
    ACCESS_ADMIN_ONLY: "admin",
    ACCESS_ORGANIZERS: "organizer",
    ACTION_CONTINUE: "continue",
    ACTION_REJECT: "reject",
    ACTION_ONBOARD: "onboard",
    ACTION_EMAIL_CONTINUE: "email_continue",
    ACTION_EMAIL_ONBOARD: "email_onboard",
    ACTION_LOGOUT: "logout",
}